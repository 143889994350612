import React from 'react'
import SolutionsDetails from '../templates/solutions-detail'
export default function SolutionsDetail() {
    let  idx = typeof window !=="undefined"? window.location.search.slice( window.location.search.length - 1):""
    const detailData = [
        {
            id:0,
            title:"Data security and protection for Healthcare",
            text:"Sightline security solutions combine proprietary technology with threat intelligence and extensive experience to identify cyber attackers, their plans and their methodology to prevent attackers from getting the most valuable data such as patient records, intellectual property and research assets etc.",
            SolutionFeatures:[
                {
                    title:"Trusted data collaboration with multiple stakeholders",
                    text:"The healthcare industry requires stakeholders across numerous institutions to collaborate on data in a secure manner. DTaaS provides secure collaboration for this sensitive data along with analytics tools, in a user-friendly environment.",
                },
                {
                    title:"Data-driven decisions in healthcare settings",
                    text:"Data-driven decisions are essential in healthcare and help to improve processes and human output, and to facilitate ongoing learning within healthcare systems. DTaaS empowers healthcare organizations to create better outcomes.",
                },
                {
                    title:"Anonymization of data",
                    text:"Healthcare organizations constantly deal with personally patient data. DTaaS facilitates compliance with global privacy legislation, and creates an audit trail of data usage and enforces data usage rights via smart contract, enabling precision healthcare.",
                },
            ]
        },
        {
            id:1,
            title:"Solutions for Smart Cities",
            text:"Sightline Datatrust platform (DTaaS) enables you to become a Smart City through Trusted Data Collaboration between your citizens, staff, and the city. Our platform Empowers the city with smart services while protecting the data and privacy of residents.",
            SolutionFeatures:[
                {
                    title:"Improve municipal and state services",
                    text:"DTaaS can be utilized to improve sustainability and access to civil services, streamline tenant relations in city housing, integrate community services across public sector divisions.",
                },
                {
                    title:"Protect data of citizens",
                    text:"How data is collected, processed, applied, governed and protected are fundamental in smart cities. DTaaS creates solutions that power smart cities without compromising the privacy and confidence of citizens.",
                },
                {
                    title:"Gain valuable insights from data",
                    text:"DTaaS enables data collaboration across public and private sector organizations to allow for real-time insights into everyday actions and longer term strategic decisions.",
                },
            ]
        },
        {
            id:2,
            title:"Data security for Financial Services",
            text:"Sightline Datatrust platform (DTaaS) keeps your financial data secure by preventing threat actors from stealing your employee, customer and business data. Our platform streamlines and improves customer interactions, risk mitigation and fraud prevention, which can keep hackers from capitalizing on insider trading through data theft or monetizing stolen payment card information, therefore enhancing your credibility and integrity.",
            SolutionFeatures:[
                {
                    title:"Improved risk mitigation and fraud prevention",
                    text:"Financial services institutions face increasing regulatory compliance requirements and also require adaptable risk mitigation and fraud prevention measures. DTaaS regulatory compliance and enables institutions to rapidly respond to these needs.",
                },
                {
                    title:"Data-driven decisions in financial services",
                    text:"Prompt, secure and timely access to data is essential for financial services institutions. DTaaS empowers these institutions to make more-informed decisions through a single source of truth datatrust.",
                },
                {
                    title:"Data monetization and securitization",
                    text:"DCreate new revenue streams from data and derivative data through licensing arrangements with third parties. Use data as a means to shore up balance sheets and unlock shareholder value.",
                },
            ]
        }
    ]
  return (
   <SolutionsDetails data={detailData[idx]}/>
  )
}
