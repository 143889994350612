import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { navigate } from "gatsby"
import arrowRight from "../images/home/arrow_right.png"
import helicopter from "../images/solutions/helicopter.png"
import fileImg from "../images/solutions/fileImg.png"
import solutionImg1 from "../images/solutions/solution_features_img1.png"
import solutionImg2 from "../images/solutions/solution_features_img2.png"
import solutionImg3 from "../images/solutions/solution_features_img3.png"
import wonderfulImg1 from "../images/solutions/wonderful_cases_img1.png"
import wonderfulImg2 from "../images/solutions/wonderful_cases_img2.png"
import wonderfulCasesImg1 from "../images/solutions/partnersImg1.png"
import wonderfulCasesImg2 from "../images/solutions/partnersImg2.png"
import wonderfulCasesImg3 from "../images/solutions/partnersImg3.png"
import wonderfulCasesImg4 from "../images/solutions/partnersImg4.png"
import wonderfulCasesImg5 from "../images/solutions/partnersImg5.png"
import topImg from "../images/solutions/solutionDetailTopImg.png"
import "../style/solutions.less"
import Fade from "react-reveal/Fade"
export default function SolutionsDetail(props) {
    const painPointsList = [
        { id: 0, img: fileImg, title: "Lack of top-level design and planning", text: "Provide growth methodology, combine the actual experience of serving customers, and customize solutions for each customer" },
        { id: 1, img: fileImg, title: "Lack of strong technical support", text: "Provide growth methodology, combine the actual experience of serving customers, and customize solutions for each customer" },
        { id: 2, img: fileImg, title: "Most lack growth programs", text: "Provide growth methodology, combine the actual experience of serving customers, and customize solutions for each customer" }
    ]
    const wonderfulCasesList = [
        { id: 0, img: wonderfulImg1, title: "Smart Cities Toronto", text: "The City of Toronto contracted Sightline Innovation to explore the use of Datatrust technology for various Connected Community use cases." },
        { id: 1, img: wonderfulImg2, title: "Public Health Agency of Canada", text: "PHAC leveraged the Sightline Datatrust and AI platform technology to monitor mental health and behavioural patterns." },
        { id: 1, img: wonderfulImg2, title: "Public Health Agency of Canada", text: "PHAC leveraged the Sightline Datatrust and AI platform technology to monitor mental health and behavioural patterns." },
        { id: 1, img: wonderfulImg2, title: "Public Health Agency of Canada", text: "PHAC leveraged the Sightline Datatrust and AI platform technology to monitor mental health and behavioural patterns." }
    ]
    const wonderfulCasesImg = [
        wonderfulCasesImg1,
        wonderfulCasesImg2,
        wonderfulCasesImg3,
        wonderfulCasesImg4,
        wonderfulCasesImg5
    ]
    return (
        <div className='solutions'>
            <Header />
            {/* Tailored security solutions for your industry */}
            <div className='detailTop'>
                <div className='detailTop_content'>
                    <Fade distance={"20%"} top duration={1300} top>
                        <h3>{props.data?.title || ''}</h3>
                    </Fade>
                    <p>{props.data?.text || ''}</p>
                    <div onClick={() => navigate("/contactUs")} className='more'>More<img src={arrowRight} alt="" /></div>
                </div>
                <div className='helicopter'>
                    <img src={topImg} alt="" />
                </div>
            </div>
            {/* Industry pain points */}
            <div className='pain_points'>
                <Fade distance={"20%"} top duration={1300} top>
                    <h3 className='h3'>Industry pain points</h3>
                </Fade>
                <h6 className='h6'>Challenges faced</h6>
                <ul>
                    {
                        painPointsList.map(item => {
                            return (
                                <li key={item.id}>
                                    <div><img src={item.img} alt="" /></div>
                                    <h3>{item.title}</h3>
                                    <p>{item.text}</p>
                                </li>
                            )
                        })
                    }

                </ul>
            </div>
            {/* Solution Features */}
            <div className='solution_features'>
                <Fade distance={"20%"} top duration={1300} top>
                    <h3 className="h3">Solution Features</h3>
                </Fade>
                <h6 className='h6'>Solution features meet the special needs of healthcare industry</h6>
                <ul>
                    <li>
                        <div className='solution_features_img'>
                            <img src={solutionImg1} alt="" />
                        </div>
                        <div className='solution_features_text'>
                            <Fade distance={"20%"} top duration={1300} top>
                                <h3>{props.data?.SolutionFeatures[0].title || ''}</h3>
                            </Fade>
                            <p>{props.data?.SolutionFeatures[0].text || ''}</p>
                        </div>
                    </li>
                    <li>
                        <div className='solution_features_text'>
                            <Fade distance={"20%"} top duration={1300} top>
                                <h3>{props.data?.SolutionFeatures[1].title || ''}</h3>
                            </Fade>
                            <p>{props.data?.SolutionFeatures[1].text || ''}</p>
                        </div>
                        <div className='solution_features_img'>
                            <img src={solutionImg2} alt="" />
                        </div>
                    </li>
                    <li>
                        <div className='solution_features_img'>
                            <img src={solutionImg3} alt="" />
                        </div>
                        <div className='solution_features_text'>
                            <Fade distance={"20%"} top duration={1300} top>
                                <h3>{props.data?.SolutionFeatures[2].title || ''}</h3>
                            </Fade>
                            <p>{props.data?.SolutionFeatures[2].text || ''}</p>
                        </div>
                    </li>
                </ul>

            </div>
            {/* Recent wonderful cases */}
            <div className='wonderful_cases'>
                <Fade distance={"20%"} top duration={1300} top>
                    <h3 id='benefits' className='h3'>Recent wonderful cases</h3>
                </Fade>
                <h6 className='h6'>An in-depth look at successful applications of our product in the healthcare industry</h6>
                <div className='wonderful_cases_content'>
                    <ul>
                        {
                            wonderfulCasesList.map((item, index) => {
                                return (
                                    <li onClick={() => navigate("/case")} key={item.id}>
                                        <div><img src={item.img} alt="" /></div>
                                        <h3>{item.title}</h3>
                                        <p>{item.text}</p>
                                    </li>
                                )
                            })
                        }

                    </ul>
                </div>
                <div onClick={() => navigate("/case")} className='more'>More<img src={arrowRight} alt="" /></div>

            </div>
            <div className='footer_logo'>
                <Fade distance={"20%"} top duration={1300} top>
                    <h3>Do you have similar difficulties to solve?</h3>
                </Fade>
                <div onClick={() => navigate("/contactUs")}>Get Started</div>
            </div>
            <Footer />
        </div>
    )
}
